document.addEventListener('keydown', function (event) {
    if (event.key === 'Escape') {
        closeEventDetails();
    }
});

window.onClickOnEvent = function (clickEvent) {
    let eventElement = clickEvent.target;
    while (!eventElement.dataset.event) {
        eventElement = eventElement.parentElement;
    }

    let event = JSON.parse(eventElement.dataset.event);

    let body = document.getElementsByTagName("body")[0];
    body.style.overflow = "hidden";

    let eventDetails = document.getElementById("EventDetails");
    eventDetails.style.display = (eventDetails.style.display === 'none') ? 'block' : 'none';

    let eventTitle = document.getElementById("EventTitle");
    eventTitle.innerHTML = event.title;

    let eventDate = document.getElementById("EventDate");
    let dateFrom = new Date(event.from * 1000);
    let dateTo = new Date(event.to * 1000);
    if (eventSameDay(event)) {
        eventDate.innerHTML = formatDateToDay(dateFrom) + " " + formatDateToHour(dateFrom) + " → " + formatDateToHour(dateTo);
    } else {
        eventDate.innerHTML = formatDateToDay(dateFrom) + " " + formatDateToHour(dateFrom) + " → " + formatDateToDay(dateTo) + " " + formatDateToHour(dateTo);
    }

    if (event.places.length > 0) {
        let placesContainer = document.getElementById("EventPlaces");
        placesContainer.innerHTML = "";
        placesContainer.className = "chipsContainer";
        placesContainer.appendChild(createBulletElement());
        event.places.forEach(function (place) {
            const placeElement = createPlaceElement(place);
            placesContainer.appendChild(placeElement);
        });
    }

    let eventDescription = document.getElementById("EventDescription");
    eventDescription.innerHTML = event.description;

    let groupsContainer = document.getElementById("EventGroups");
    groupsContainer.innerHTML = "";
    groupsContainer.className = "chipsContainer";
    event.groups.forEach(function (group) {
        const groupChip = createGroupChipElement(group);
        groupsContainer.appendChild(groupChip);
    });
}

window.closeEventDetails = function () {
    let eventDetails = document.getElementById("EventDetails");

    eventDetails.style.display = 'none';

    let body = document.getElementsByTagName("body")[0];
    body.style.overflow = "auto";
}